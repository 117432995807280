<template>
  <div class="index">
    <div class="bg"></div>
    <div class="top">
      <div class="right">
        <span class="name">{{title}}</span>
      </div>
    </div>
    <div class="middle">
      <!-- <div>活动名称</div> -->
      <video style="border-radius: 0.3125rem;" controls
        poster="https://abc.xnrun.com/image/default/8B88C38BE0A5483F8CC22E401DFA35B4-6-2.png">
        <source src="https://abc.xnrun.com/d0e282b6b6aa45d7a49be641d8d5b0d2/a64a8e67a9fb41e9880e6e088f5dfb55-69de24847ac1efd88136e78fe57695dc-sd.mp4" type="video/mp4">
      </video>
      <div style="text-align: left;">
        <b>活动目录</b>
        <div @click="gotoDownload()" class="mlys" v-for="(item,index) in tasks" :key="index">
          {{item}}
        </div>
      </div>
    </div>
    <div class="gotoAPP" @click="gotoDownload()">
      <span>前往App了解活动</span>
    </div>
    <div class="fixedBottom">
      <div>
        <img src="https://abc.xnrun.com/image/cover/426CA11BFBB94DD3BB351EE578F04487-6-2.png" alt="">
        <span>小聂跑法 App</span>
      </div>
      <div class="fixedBottom-right" @click="gotoDownload()">立即打开</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'curriculum',
    data() {
      return {
        title: '',
        tasks: [],
        widthORheight: ''
      }
    },
    created() {
      this.title = this.$route.query.name
      var jsonStr = this.$route.query.tasks
      this.tasks = JSON.parse(jsonStr)
      document.title = '小聂跑法'
    },
    methods: {
      gotoDownload() {
        this.$router.push('/share/download')
      }
    }

  }
</script>

<style lang="scss" scoped>
  .mlys {
    width: 100%;
    background-color: #FF7708;
    text-align: center;
    font-size: 1.1625rem;
    border-radius: 5px;
    margin: 1% 0;

  }

  .index {
    @media screen and (min-width: 600px) {
      width: 50%;
      margin: 0 auto;
    }

    padding: 20px 5vw;
    padding-bottom: 80px;
    // margin: 0 5vw;
    // background-color: #8B4513;
    color: white;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;

    .bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url('https://abc.xnrun.com/image/cover/B4D1BC4E0DF04C3E86F36248E8F6C9DA-6-2.png');
      // background-color: #111230;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

    }

    .top {
      display: flex;

      .right {
        display: flex;
        flex-direction: column;
      }
    }

    .middle {
      .content {
        // margin: 10px 0;
        margin-top: 10px;
        margin-bottom: 0;
      }

      .imgs {
        // display: grid;
        // grid-template-columns: repeat(3, calc((100vw - 10vw - 5px) / 3));
        // grid-template-rows: repeat(2, calc((100vw - 10vw - 5px) / 3));
        // grid-gap: 5px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        .el-image {
          width: calc((100vw - 10vw - 10px) / 3);
          height: calc((100vw - 10vw - 10px) / 3);
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }

      video {
        width: 100%;
        // transform: translateX(-5vw);
        height: 60vw;
        margin-bottom: 10px;
      }
    }

    .tooler {
      width: 100vw;
      height: 3px;
      transform: translateX(-5vw);
      margin: 20px 0;
      background-color: #fff;
    }

    .bottom {
      margin-top: 30px;

      .title {
        font-weight: bold;
        font-size: 1.4em;
      }

      .content {
        .item {
          display: flex;

          .right {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .gotoAPP {
      color: white;
      border: 2px solid white;
      height: 50px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 20px;
    }

    .fixedBottom {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10px;
      align-items: center;
      background-color: #111230;
      width: 100vw;
      height: 80px;

      img {
        border-radius: 10px;
      }

      .fixedBottom-right {
        // background-color: white;
        border: 1px solid orange;
        color: orange;
        padding: 5px;
        border-radius: 5px;
      }
    }

    p,
    span {
      text-align: left;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #eee;
      margin-right: 10px;
    }

    .name {
      font-weight: bold;
      font-size: 1.2em;
    }
  }
</style>
