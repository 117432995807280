<template>
	<div class="download">
		<img src="https://abc.xnrun.com/image/cover/8C69A69E9E614620BAA81D8246FF4141-6-2.png" alt="">
		<!-- <span>小聂跑法®</span> -->
		<!-- <img src="https://abc.xnrun.com/image/cover/A600FD8A14314A7E9A125E6F0BD65FA2-6-2.png" alt=""> -->
		<a v-if="isIOS" href="https://itunes.apple.com/app/小聂跑法/id1535573216">下载 App<text>{{brand}}111</text></a>
		<a  v-else @click="download()">下载 App{{text}}</a>
		
		<el-button v-if="code" class="cpLink" type="success" v-clipboard:error="onError" v-clipboard:copy="code"
			v-clipboard:success="onCopy" round>
			邀请码：{{code}}(点击复制)
		</el-button>
		<!-- 微信打开弹出提示 -->
		<img v-if="isWeChat && !isIOS" class="tips"
			src="https://abc.xnrun.com/image/cover/B9E65BE823A54CA88FD9AD2FA58A7C33-6-2.png" alt="">
	</div>
</template>


<script>
	import VueClipboard from 'vue-clipboard2'
	import Vue from 'vue'
	Vue.use(VueClipboard)
	export default {
		data() {
			return {
				// isShowTips: false
				text: '',
				brand: null,
				os: null,
				brands: {
					"IPHONE": "IPHONE|IPAD|IPOD|IOS",
					"OPPO": "OPPO",
					"VIVO": "VIVO",
					"HONOR": "HONOR",
					"HUAWEI": "HUAWEI",
					"XIAOMI": "XIAOMI|REDMI",
					"360": "1801-A01|1707-A01|1509-A00",
					"SAMSUNG": "SAMSUNG"
				},
				hasOwnProp: null,
				userAgent: null,
				is_weixin: null,
				is_dingtalk: null,
				code: ''
			}
		},
		computed: {
			isWeChat() {
				return RegExp(/WeChat/).test(navigator.userAgent)
			},
			isIOS() {
				return RegExp(/\(i[^;]+;( U;)? CPU.+Mac OS X/).test(navigator.userAgent)
			}
		},
		created() {
			this.code = this.$route.query.code ===undefined||this.$route.query.code =='undefined' || this.$route.query.code =='null'||this.$route.query.code ==null?'':this.$route.query.code
			document.title = '小聂跑法App下载'
		},
		methods: {
			onCopy(e) {
				console.log('s');
				// this.$Toast({content: '复制成功', duration: 1500})
				this.$message.success('复制成功')
			},
			// 复制失败
			onError(e) {
				console.log('e');
				// this.$Toast({content: '复制失败', duration: 1500})
				this.$message.success('复制失败')
			},
			download() {
				this.hasOwnProp = Object.prototype.hasOwnProperty;
				this.userAgent = navigator.userAgent.toUpperCase();
				console.log(this.userAgent)
				this.is_weixin = (function() {
					if (navigator.userAgent.toUpperCase().match(/MicroMessenger/i) == "MICROMESSENGER") {
						return true;
					} else {
						return false;
					}
				})();
				this.is_dingtalk = (function() {
					if (navigator.userAgent.toUpperCase().match(/DingTalk/i) == "DINGTALK") {
						return true;
					} else {
						return false;
					}
				})();

				for (var key in this.brands) {
					if (this.hasOwnProp.call(this.brands, key)) {
						this.brands[key] = new RegExp(this.brands[key], 'i');
					}
				}

				this.os = this.getOS();
				this.brand = this.getBrand();


				this.downloadApp(this.brand);

				// if(this.isWeChat){
				// this.isShowTips = true
				// }else{
				// location.href = 'https://xnpfapp.oss-cn-shenzhen.aliyuncs.com/apk/xnpf.apk'
				// }
			},




			/**
			 * 获取系统名称，安卓或者ios
			 */
			getOS() {
				if (this.userAgent.indexOf("ANDROID") > -1) {
					return "ANDROID";
				} else if (this.userAgent.indexOf("IPHONE") > -1) {
					return "IOS";
				}
			},

			/**
			 * 获取设备品牌
			 */
			getBrand() {
				var result = [];
				for (var key in this.brands) {
					if (this.hasOwnProp.call(this.brands, key)) {
						if (this.brands[key].test(this.userAgent)) {
							result.push(key);
						}
					}
				}
				return result[0];
			},
			downloadApp(brand) {
				this.text = '1进来了'
				if (this.os === "IOS") {
					window.location.href = "https://itunes.apple.com/cn/app/id1535573216";
					return;
				}
				this.text = '2进来了'
				console.log(brand)
				location.href = 'https://xnpfapp.oss-cn-shenzhen.aliyuncs.com/apk/xnpf.apk'
				// switch (brand) {
				// 	case "HONOR":
				// 		this.text = '3进来了'
				// 		window.location.href =
				// 			'intent://details?id=xnpf.huawei#Intent;package=com.huawei.appmarket;scheme=market;end;';
				// 		break;
				// 	case "HUAWEI":
				// 	case "OPPO":
				// 		window.location.href = "market://details?id=xnpf.huawei";
				// 		break;
				// 	case "XIAOMI":
				// 		this.text = '5进来了'
				// 		// location.href  ='intent://details?id=xnpf.huawei#Intent;package=com.xiaomi.market;scheme=market;end;';
				// 		location.href = 'mimarket://details?id=xnpf.huawei'
				// 		// plus.runtime.openURL('intent://details?id=xnpf.huawei#Intent;package=com.xiaomi.market;scheme=market;end;')
				// 		break;
				// 	case "VIVO":
				// 		window.location.href =
				// 			'intent://details?id=apk的包名#Intent;package=com.bbk.appstore;scheme=market;end;';
				// 		break;
				// 	case "SAMSUNG":
				// 		window.location.href = "http://www.samsungapps.com/appquery/appDetail.as?appId=apk的包名";
				// 		break;
				// 	case "360":
				// 		window.location.href =
				// 			'intent://details?id=apk的包名#Intent;package=com.qihoo.appstore;scheme=market;end;';
				// 		break;
				// 	default:
				// 		this.text = '5进来了'
				// 		window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=xnpf.huawei";
				// 		break;
				// }
			}
		},
	}
</script>

<style scoped lang="scss">
	.download {
		@media screen and (min-width: 600px) {
			width: 30%;
			margin: 0 auto;
		}

		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-image: url('https://abc.xnrun.com/image/cover/58075BC1FEE34A91B06545C785894B2D-6-2.png');
		background-size: cover;
		background-position: center;
		padding-top: 10px;

		// img{
		//   width: 30vw;
		//   height: 30vw;
		//   border-radius: 20px;
		// }
		img {
			&:first-child {
				width: 100%;
			}
		}

		a {
			background-color: #E83A17;
			background-color: #E5691A;
			border: 1px solid white;
			color: white;
			width: 50%;
			height: 40px;
			font-size: 20px;
			line-height: 40px;
			text-decoration: none;
			margin: 20px 0;
			border-radius: 5px;

			&:hover {
				color: white;
				cursor: pointer;
			}
		}

		.tips {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 0;
			z-index: 2021;
		}
	}
</style>
