<template>
  <div class="index">
    <div class="bg"></div>
    <div class="top">
      <img class="avatar" :src="item.imgurl" alt=""/>
      <div class="right">
        <span class="name">{{item.name}}</span>
      </div>
    </div>
    <div class="middle">
      <p class="content">{{item.content}}</p>
      <!-- 视频 -->
      <video v-if="item.sort === 0" controls :poster="item.litimg">
        <source :src="item.vedio" type="video/mp4">
      </video>
      <!-- 图片 -->
      <div v-else-if="item.sort === 1" class="imgs">
        <template v-if="imgs.length > 1">
          <el-image
            v-for="(item3,index) in imgs"
            :key="index"
            fit="cover"
            :src="item3"
            :preview-src-list="imgs">
          </el-image>
        </template>
        <template v-else>
          <el-image
            fit="cover"
            :src="imgs[0]"
            :preview-src-list="imgs"
            :style="{
            	width: widthORheight === 'width' ? '100%' : '50vw',
            	height: widthORheight === 'height' ? '80vw' : '50vw',
              margin: '0'
            }"
            >
          </el-image>
        </template>
      </div>
    </div>

    <template v-if="item.commentsNum > 0">
      <div class="tooler"></div>
      <div class="bottom">
        <p class="title">全部评论（{{item.commentsNum}}）</p>
        <div class="content">
          <div class="item" v-for="(item2,index) in item.comments" :key="index">
            <img class="avatar" :src="item2.image" alt=""/>
            <div class="right">
              <span class="name">{{item2.name}}</span>
              <p>{{item2.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="gotoAPP" @click="gotoDownload()">
      <span>去App查看全部评论</span>
    </div>


    <div class="fixedBottom">
      <div>
        <img src="https://abc.xnrun.com/image/cover/426CA11BFBB94DD3BB351EE578F04487-6-2.png" alt="">
        <span>小聂跑法 App</span>
      </div>
      <div class="fixedBottom-right" @click="gotoDownload()">立即打开</div>
    </div>

  </div>
</template>

<script>
export default{
name: 'share',
  data(){
    return{
      item: {},
      imgs: [],
      widthORheight: ''
    }
  },
  created() {
    console.log({url:this.$route.query.id})
    document.title = '小聂跑法'
    this.getItem()
  },
  methods:{
    async getItem(){
      let res = await this.$http.get('/app/getSqdtByuuid',{
        params: {
          uuid: this.$route.query.id
        }
      })
      console.log(res)
      this.item = res.data.data
      this.imgs = this.item.imgurls.split(',')
      console.log(this.imgs)
      if(this.imgs.length === 1){
        let img = new Image()
        img.src = this.imgs[0]
        img.onload = ()=>{
          console.log({
            width: img.width,
            height: img.height
          })
          if( img.width > img.height ){
            this.widthORheight = 'width'
          }else if( img.width < img.height ){
            this.widthORheight = 'height'
          }else{
            this.widthORheight = 'same'
          }
          console.log({widthORheight:this.widthORheight})
        }
        // let intervel = setInterval(()=>{
        //   if(img.complete){
        //     console.log({
        //       width: img.width,
        //       height: img.height
        //     })
        //     if( img.width > img.height ){
        //     	this.widthORheight = 'width'
        //     }else if( img.width < img.height ){
        //     	this.widthORheight = 'height'
        //     }else{
        //     	this.widthORheight = 'same'
        //     }
        //     console.log({widthORheight:this.widthORheight})
        //     clearInterval(intervel)
        //   }
        // },100)
      }
    },
    gotoDownload(){
      this.$router.push('/share/download')

    }
  }

}
</script>

<style lang="scss" scoped>
  .index{
    @media screen and (min-width: 600px) {
      width: 50%;
      margin: 0 auto;
    }
    padding: 20px 5vw;
    padding-bottom: 80px;
    // margin: 0 5vw;
    // background-color: #8B4513;
    color: white;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    .bg{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url('https://abc.xnrun.com/image/cover/B4D1BC4E0DF04C3E86F36248E8F6C9DA-6-2.png');
      // background-color: #111230;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

    }
    .top{
      display: flex;
      .right{
        display: flex;
        flex-direction: column;
      }
    }
    .middle{
      .content{
        // margin: 10px 0;
        margin-top: 10px;
        margin-bottom: 0;
      }
      .imgs{
        // display: grid;
        // grid-template-columns: repeat(3, calc((100vw - 10vw - 5px) / 3));
        // grid-template-rows: repeat(2, calc((100vw - 10vw - 5px) / 3));
        // grid-gap: 5px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .el-image{
          width: calc((100vw - 10vw - 10px) / 3);
          height: calc((100vw - 10vw - 10px) / 3);
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
      video{
        width: 100%;
        // transform: translateX(-5vw);
        height: 60vw;
        margin-bottom: 10px;
      }
    }
    .tooler{
      width: 100vw;
      height: 3px;
      transform: translateX(-5vw);
      margin: 20px 0;
      background-color: #fff;
    }
    .bottom{
      margin-top: 30px;
      .title{
        font-weight: bold;
        font-size: 1.4em;
      }
      .content{
        .item{
          display: flex;
          .right{
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .gotoAPP{
      color: white;
      border: 2px solid white;
      height: 50px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 20px;
    }

    .fixedBottom{
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10px;
      align-items: center;
      background-color: #111230;
      width: 100vw;
      height: 80px;
      img{
        border-radius: 10px;
      }
      .fixedBottom-right{
        // background-color: white;
        border: 1px solid orange;
        color: orange;
        padding: 5px;
        border-radius: 5px;
      }
    }
    p,span{
      text-align: left;
    }
    img{
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #eee;
      margin-right: 10px;
    }
    .name{
      font-weight: bold;
      font-size: 1.2em;
    }
  }
</style>
