<template>
  <div class="personal">
    <div class="bg"></div>
    <PhotoWallDetail :data="item"></PhotoWallDetail>
  </div>
</template>

<script>
  import PhotoWallDetail from '../PhotoWall/PhotoWallDetail.vue'
  export default{
    data(){
      return{
        item:{}
      }
    },
    created() {
      console.log(this.$route.query.uuid)
      this.getData()
    },
    components:{
      PhotoWallDetail
    },
    methods:{
      async getData(){
        let res = await this.$http.get('/official/student/getByUuid',{
          params: {
            uuid: this.$route.query.uuid
          }
        })
        console.log(res)
        this.item = res.data.data
        this.item.intro= this.item.intro.replace(/<img/g, '<img style="width: auto;height: auto; max-width: 100%;max-height: 100%;" ');
        this.item.honorImage = this.item.honorImage.split(',')
        this.item.video = this.item.video.split(';')
      }
    }
  }
</script>

<style>
  @media only screen and (min-width:768px) {
    .personal{
      color: #ddd;
    }
  }

  .photo_wall_detail {
    padding: 2vh;
  }
  .bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url('https://abc.xnrun.com/image/cover/B4D1BC4E0DF04C3E86F36248E8F6C9DA-6-2.png');
    /* background-color: #111230; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  }
</style>
