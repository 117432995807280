<template>
  <div class="photo_wall_detail">
    <div v-if="windowWidth > 1200">
      <div class="photo_wall_detail_left">
        <img :src="data.personalImage" alt="">
      </div>
      <div class="photo_wall_detail_right">
        <div class="photo_wall_detail_right_intro" v-html="data.intro">
          {{data.intro}}
        </div>
        <div class="photo_wall_detail_right_imgs">
          <el-image style="width: 200px; height: 200px" v-for="(item,index) in data.honorImage" :key="index" :src="item"
            :preview-src-list="data.honorImage"
            fit="cover">
          </el-image>
        </div>
        <!-- 视频 -->
        <div class="photo_wall_detail_right_video">
          <div class="wrapper" v-for="(item,index) in data.video" :key="index">
            <video :controls="curIndex === index ? true : false" :src="item" :ref="'video'+index">
            </video>
            <i v-show="curIndex === index ? false : true" class="iconfont" @click="onVideo(index)">&#xe615;</i>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="mobile">
      <div style="background-color: #FFFFFF;border-radius: 10px;padding: 2% 0;margin: 2% 0;">
        <img :src="data.personalImage" alt="" style="width: 100%;">
        <div class="photo_wall_detail_right_intro" v-html="data.intro">
          {{data.intro}}
        </div>
      </div>
      <div class="photo_wall_detail_right_imgs">
        <el-image style="width: 49%; height: 40vw;" v-for="(item,index) in data.honorImage" :key="index" :src="item"
          :preview-src-list="data.honorImage"
          fit="cover">
        </el-image>
      </div>
      <!-- 视频 -->
      <div class="photo_wall_detail_right_video">
        <div class="wrapper" v-for="(item,index) in data.video" :key="index">
          <video :controls="curIndex === index ? true : false" :src="item" :ref="'video'+index">
          </video>
          <i v-show="curIndex === index ? false : true" class="iconfont" @click="onVideo(index)">&#xe615;</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        srcList: [
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
          'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
        ],
        windowWidth: window.screen.width,
        curIndex: '',
        isPlay: false
      }
    },
    props: {
      data: Object
    },
    created() {
      console.log(this.data)
      this.data.video = this.data.video.split(';')
      this.data.honorImage = this.data.honorImage.split(',')
          if(this.data.honorImage[this.data.honorImage.length-1] === ''){
            this.data.honorImage.pop()
          }
    },
    methods: {
      onVideo(index) {
        // console.log(this.$refs['video3'])
        this.curIndex = index
        this.isPlay = !this.isPlay
        if (this.$refs['video' + index][0].paused) {
          this.$refs['video' + index][0].play()
        } else {
          this.$refs['video' + index][0].pause()
        }
        for (let i = 0; i < this.data.video.length; i++) {
          if (i !== index) {
            this.$refs['video' + i][0].pause()
          }
        }

      }
    }
  }
</script>

<style lang="scss" scoped>
  @font-face {
    font-family: 'iconfont';
    /* project id 2426134 */
    src: url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.eot');
    src: url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.eot?#iefix') format('embedded-opentype'),
      url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.woff2') format('woff2'),
      url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.woff') format('woff'),
      url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.ttf') format('truetype'),
      url('//at.alicdn.com/t/font_2426134_c2jdbz4rhep.svg#iconfont') format('svg');
  }

  .photo_wall_detail {
    // background-image: url('https://abc.xnrun.com/image/cover/B4D1BC4E0DF04C3E86F36248E8F6C9DA-6-2.png');
    // // background-color: #111230;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;

    // width: 1200px;
    // margin: 0 auto;
    // padding-top: 10px;
    .photo_wall_detail_left {
      width: 30%;
      display: inline-block;
      vertical-align: top;

      img {
        width: 100%;
      }
    }

    .photo_wall_detail_right {
      width: 610px;
      display: inline-block;
      position: relative;
      padding: 30px;
      box-sizing: content-box;

      .photo_wall_detail_right_intro {
        text-align: left;
      }

      .photo_wall_detail_right_imgs {
        font-size: 0;
        text-align: left;
        width: 100%;
        box-sizing: border-box;

        .el-image {
          margin-right: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #ebeef5;

          &:hover {
            // transform: translateY(-2%);
            box-shadow: 1px 1px 5px 2px #CCC;
            transition: .3s;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }

      // 视频
      .photo_wall_detail_right_video {
        font-size: 0;
        text-align: left;
        width: 100%;
        box-sizing: border-box;

        .wrapper {
          position: relative;
          margin-right: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          display: inline-block;
          width: 200px;
          height: 200px;
          box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #ebeef5;

          &:hover {
            // transform: translateY(-2%);
            box-shadow: 1px 1px 5px 2px #CCC;
            transition: .3s;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: rgba($color: #fff, $alpha: 1);
            cursor: pointer;
          }
        }

      }
    }

    @media screen and (max-width:768px) {

      .mobile {
        .photo_wall_detail_right_imgs {
          font-size: 0;
          text-align: left;
          width: 100%;
          box-sizing: border-box;

          .el-image {
            margin-right: 2%;
            margin-bottom: 5px;
            box-sizing: border-box;
            box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid #ebeef5;
            border-radius: 5px;
            &:hover {
              // transform: translateY(-2%);
              box-shadow: 1px 1px 5px 2px #CCC;
              transition: .3s;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }

        // 视频
        .photo_wall_detail_right_video {
          font-size: 0;
          text-align: left;
          width: 100%;
          box-sizing: border-box;

          .wrapper {
            position: relative;
            margin-right: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            display: inline-block;
            width: 40vw;
            height: 40vw;
            box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid #ebeef5;

            &:hover {
              // transform: translateY(-2%);
              box-shadow: 1px 1px 5px 2px #CCC;
              transition: .3s;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            i {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 50px;
              color: rgba($color: #fff, $alpha: 1);
              cursor: pointer;
            }
          }

        }
      }
    }
  }
</style>
